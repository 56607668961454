import Swipe from 'swipejs';

window._swipers = {};
let swipers;

const triggerSlider = (sliderElement) => {
    const sliderDotsElement = sliderElement.getElementsByClassName('swipe-dots')[0];
    const sliderName = sliderElement.dataset.name;

    if (window.innerWidth <= 640 && !window._swipers[sliderName]) {
        console.info(`[swipers.js] Starting ${sliderName}`);

        const slider = new Swipe(sliderElement, {
            auto: 3500,
            callback(currentSlideIdx) {
                [].forEach.call(sliderDotsElement.children, (node, idx) => {
                    node.classList.toggle('active', currentSlideIdx == idx);
                });
            },
        });

        if (! sliderDotsElement.dataset.inited) {
            let html = '';

            for (let i = 0; i < slider.getNumSlides(); i++) {
                const className = i == slider.getPos() ? 'active' : '';
                html += `<span class=${className}></span>`;
            }

            sliderDotsElement.innerHTML = html;
            sliderDotsElement.dataset.inited = true;
        }

        window._swipers[sliderName] = slider;
    }

    if (window.innerWidth > 640 && window._swipers[sliderName]) {
        console.info(`[swipers.js] Killing ${sliderName}`);

        window._swipers[sliderName].kill();
        window._swipers[sliderName] = null;
    }
};

const triggerAllSliders = () => {
    for (let swiper of swipers) {
        triggerSlider(swiper);
    }
};

export default function registerSwipers() {
    swipers = document.getElementsByTagName('x-swiper');
    triggerAllSliders();

    window.addEventListener('resize', triggerAllSliders);
};
