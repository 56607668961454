import http from './http';
import registerSwipers from './swipers';
import registerClipboards from './clipboards';

const registerMetricsUpdater = () => {
    const metrics = document.querySelectorAll("#metrics-container [data-metric]");
    const formatter = new Intl.NumberFormat();

    http.get('/node_info.json').then((result) => {
        [].forEach.call(metrics, (node) => {
            const metric_name = node.dataset.metric;

            if (metric_name in result) {
                node.innerHTML = formatter.format(result[metric_name]);
            }
        });
    });
};

document.addEventListener('DOMContentLoaded', () => {
    registerMetricsUpdater();
    registerSwipers();
    registerClipboards();
});
