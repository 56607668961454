export default {
    get(url, query) {
        // Builds HTTP query:
        if (typeof query == 'object') {
            let queryString = '';
            for (let key in query) {
                queryString += `${key}=${query[key]}&`;
            }
            url += `?${queryString}ajax=true`;
        }

        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.open('GET', url);
            req.setRequestHeader('Accept', 'application/json, text/javascript, */*; q=0.01');
            req.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            req.onload = () => {
                if (req.status < 200 || req.status >= 300) {
                    reject(Error(req.statusText));
                } else {
                    resolve(JSON.parse(req.response));
                }
            };
            req.onerror = (e) => reject(Error(`Network Error: ${e}`));
            req.send();
        });
    },
};
