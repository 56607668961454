import ClipboardJS from 'clipboard';

export default function registerClipboards() {
    const clipboardElements = document.querySelectorAll('[data-clipboard-text]');

    for (let element of clipboardElements) {
        const clipboardObject = new ClipboardJS(element);

        clipboardObject.on('success', (e) => {
            if (element.dataset.successText && element.dataset.successIcon) {
                return alert('Пока нельзя делать так, чтобы менялась и иконка, и текст');
            }

            if (element.dataset.successIcon) {
                const icon = element.getElementsByTagName('use')[0];
                const originalIcon = icon.getAttribute('xlink:href');

                element.disabled = true;
                icon.setAttribute('xlink:href', element.dataset.successIcon);

                setTimeout(() => {
                    element.disabled = false;
                    icon.setAttribute('xlink:href', originalIcon);
                }, 1000);
            }

            else if (element.dataset.successText) {
                const originalText = element.innerText;

                element.disabled = true;
                element.innerText = element.dataset.successText;

                setTimeout(() => {
                    element.disabled = false;
                    element.innerText = originalText;
                }, 1000);
            }
        });
    }
};
